var isMobile = false;
var isiPhoneiPad = false;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
}
if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    isiPhoneiPad = true;
}

$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('.navbar').addClass('add_class')
        $('.brand').addClass('add_class')
    } else {
        $('.navbar').removeClass('add_class')
        $('.brand').removeClass('add_class')
    }
});

var cartPopup = '.cart_popup';
var cartBtnId = '#cart';
$(cartBtnId).click(function () {
    $(cartPopup).toggleClass('active');
});
$(document).click(function (e) {
    if (!$(e.target).parents().andSelf().is(cartBtnId)) {
        $(cartPopup).removeClass("active");
    }
});
$(cartPopup).click(function (e) {
    e.stopPropagation();
});

// Mobile Menu
$(".navbar-nav").clone().prependTo("#off-canvas");

$(function() {
    $(document).trigger("enhance");
});

$('#off-canvas').offcanvas({
// options
});

// $('.dropdown-submenu>a').on("click", function(e){
//     $(this).next('ul').toggle();
//     e.stopPropagation();
//     e.preventDefault();
// });

$('.dropdown>a').on("click", function(e){
    $(this).toggleClass('open');
});
$('.dropdown-submenu>a').on("click", function(e){
    $(this).toggleClass('open');
});


jQuery(document).ready(function ($) {

    var $sync1 = $(".big-images"),
        $sync2 = $(".thumbs"),
        flag = false,
        duration = 300;

    $sync1
        .owlCarousel({
            items: 1,
            margin: 10,
            nav: true,
            dots: true,
            navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
            responsive:{
                0 :{
                    dots: true,
                    nav:false,
                },
                768:{
                    dots: false,
                    nav:true,
                }
            }
        })
        .on('changed.owl.carousel', function (e) {
            if (!flag) {
                flag = true;
                $sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
                flag = false;
            }
        });

    $sync2
        .owlCarousel({
            margin: 4,
            items: 4,
            nav: false,
            center: false,
            dots: false,
            responsive:{
                0 :{
                    margin: 4,
                    items: 3
                },
                768 :{
                    margin: 4,
                    items: 4
                }
            }
        })
        .on('click', '.owl-item', function () {
            $sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);

        })
        .on('changed.owl.carousel', function (e) {
            if (!flag) {
                flag = true;
                $sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
                flag = false;
            }
        });

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();
    // discount
    $('.discountTag').on('click', function(){
        alert('購買『點折商品』，結帳時將享有更多優惠哦！');
    });
});
// $(function () {
//     var austDay = new Date();
//     austDay = new Date(austDay.getFullYear() + 1, 1 - 1, 1);
//     $('.defaultCountdown').countdown({until:austDay,
//         layout: '<span>{hn} <small>{hl}</small></span><span>{mn} <small>{ml}</small></span><span>{sn} <small>{sl}</small></span>'});
// });

$('.like_btn').click(function(){
    $(this).find('i').toggleClass('fa-heart').toggleClass('fa-heart-o');
});

// Footer Banner
$(function(){
    $(".ft_bnr_crsl").owlCarousel({
        loop: true,
        margin: 5,
        autoplay: true,
        autoplayTimeout:2000,
        touchDrag: true,
        mouseDrag: true,
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsive: {
            0: {
                dots: true,
                nav: false,
                items: 3
            },
            576: {
                dots: true,
                nav: false,
                items: 4
            },
            768: {
                dots: false,
                nav: true,
                items: 6
            },
            992: {
                items: 6
            }
        }
    });

    $('#ya-search-btn').on('click', function(){
        var form = $(this).closest('form');
        form.submit();
    });

    var reCaptchaKey = $('#reCaptchaKey').val();
    var loginUrl = $('#ajaxLogin').val();
    var token = $('#ajaxToken').val();
    $('.loginBtn').on('click', function(){

        var btn = $(this);
        var form = $(this).closest('form');
        var data = form.serialize();
        var alertDiv = $("#alert");
        form.find('[name="g-recaptcha-token"]').val('');
        grecaptcha.ready(function() {
            grecaptcha.execute(reCaptchaKey, {action: 'login'})
                .then(function(token) {
                    // Verify the token on the server.
                    form.find('[name="g-recaptcha-token"]').val(token);
                });
        });

        if(form.get(0).checkValidity()){
        }else{
            var invalids = form.find(':invalid');
            var $invalid = $(invalids[0]);
            $invalid.focus();
            var placeholder = $invalid.attr('placeholder');
            if(placeholder){
                alert(placeholder);
            }
            return false
        }

        btn.attr('disabled', true);
        var timePress = 10;
        var checkToken = setInterval(doLogIn, 10);

        function doLogIn(){
            if(form.find('[name="g-recaptcha-token"]').val() !== ''){
                clearInterval(checkToken);
                data = form.serialize();

                $.ajax({
                    url: loginUrl,
                    type: 'POST',
                    dataType: 'json',
                    data: data,
                    success: function (json) {
                        // console.log(json);
                        if(json.status === 1){
                            // alert(json.message);
                            alertDiv.show();
                            alertDiv.text(json.message);
                            if(alertDiv.hasClass('alert-danger')){
                                alertDiv.removeClass('alert-danger');
                            }
                            alertDiv.addClass('alert-success');
                            alertDiv.alert();
                            setTimeout(function(){
                                window.location.href = json.to;
                                btn.removeAttr('disabled');
                            }, 500);
                        }else{
                            alertDiv.show();
                            alertDiv.text(json.message);
                            if(alertDiv.hasClass('alert-success')){
                                alertDiv.removeClass('alert-success');
                            }
                            alertDiv.addClass('alert-danger');
                            alertDiv.alert();
                            btn.removeAttr('disabled');
                            alert('登入失敗: '+json.message);
                        }
                        return false;
                    }
                }).fail(function (xhr, status, error) {
                    btn.removeAttr('disabled');
                    if(xhr.status === 422){
                        var json = xhr.responseJSON;
                        for(key in json){
                            alert(json[key][0]);
                        }
                    }else{
                        alert(xhr.responseText);
                    }
                    return false;
                });
            }else{
                timePress += 10;
            }
        }
    });

    $('.registerBtn').on('click', function () {
        var timePress = 10;
        var btn = $(this);
        var form = $(this).closest('form');
        var data = form.serialize();
        if(form.get(0).checkValidity()){
        }else{
            var invalids = form.find(':invalid');
            var $invalid = $(invalids[0]);
            $invalid.focus();
            var placeholder = $invalid.attr('placeholder');
            if(placeholder){
                alert(placeholder);
            }
            return false
        }

        btn.attr('disabled', true);

        form.find('[name="g-recaptcha-token"]').val('');
        grecaptcha.ready(function() {
            grecaptcha.execute(reCaptchaKey, {action: 'login'})
                .then(function(token) {
                    form.find('[name="g-recaptcha-token"]').val(token);
                });
        });
        btn.attr('disabled', true);
        var checkToken = setInterval(doSubmit, 10);

        function doSubmit(){
            if(form.find('[name="g-recaptcha-token"]').val() !== ''){
                form.submit();
                console.log('timePress:', timePress);
                clearInterval(checkToken);
            }else{
                console.log('timePress:', timePress);
                timePress += 10;
            }
        }
    });
});

(function() {

    window.inputNumber = function(el) {

        var min = el.attr('min') || false;
        var max = el.attr('max') || false;

        var els = {};

        els.dec = el.prev();
        els.inc = el.next();

        el.each(function() {
            init($(this));
        });

        function init(el) {

            els.dec.on('click', decrement);
            els.inc.on('click', increment);

            function decrement() {
                var value = el[0].value;
                value--;
                if(!min || value >= min) {
                    el[0].value = value;
                }
                el.trigger('change');
            }
            function increment() {
                var value = el[0].value;
                value++;
                if(!max || value <= max) {
                    el[0].value = value++;
                }
                el.trigger('change');
            }
        }
    }
})();

inputNumber($('.input-number>input'));